import LossesService from '../../services/losses';
import { AppThunk } from '..';
import { setLoading, setMessage } from '../ui';
import { setCategories, setLosses } from '.';

export function getLosses(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    
    const { token } = getState().user;
    try {
      const { data } = await LossesService.getAll(token);
      dispatch(setLosses(data.data));
      dispatch(setLoading(false));
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function getLossCategories(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    
    const { token } = getState().user;
    try {
      const { data } = await LossesService.getCategories(token);
      dispatch(setCategories(data.data));
      dispatch(setLoading(false));
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function insertLosses(losses: Losses, cb?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { token } = getState().user;

    try {
      const { data } = await LossesService.insert(token, losses);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }

      dispatch(setLoading(false));
      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function updateLosses(losses: Losses, cb?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { token } = getState().user;

    try {
      const { data } = await LossesService.update(token, losses);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }

      dispatch(setLoading(false));
      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function deleteLosses(id: number, cb?: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { token } = getState().user;

    try {
      const { data } = await LossesService.remove(token, id);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }

      dispatch(setLoading(false));
      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}
