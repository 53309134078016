import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DashboardState {
  reports: Report[],
  latestTimesheets: Timesheet[],
}

const initialState: DashboardState = {
  reports: [],
  latestTimesheets: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setReports: (state, { payload }: PayloadAction<Report[]>) => {
      state.reports = payload;
    },
    setLatestTimesheets: (state, { payload }: PayloadAction<Timesheet[]>) => {
      state.latestTimesheets = payload;
    },
  },
});