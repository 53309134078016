import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SettingsState {
  selectedLoss?: Losses | null,
  selectedMachine?: Machine | null,
  selectedProduct?: Product | null,
  selectedUserId: number,
  selectedUser?: User | null,
  selectedWorkingTime?: WorkingTime | null,
  users: User[],
  roles: Role[],
}

const initialState: SettingsState = {
  selectedUserId: -1,
  users: [],
  roles: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSelectedUserId: (state, { payload }: PayloadAction<number>) => (
      { ...state, selectedUserId: payload }
    ),
    setSelectedLoss: (state, { payload }: PayloadAction<Losses | null>) => (
      { ...state, selectedLoss: payload }
    ),
    setSelectedMachine: (state, { payload }: PayloadAction<Machine | null>) => (
      { ...state, selectedMachine: payload }
    ),
    setSelectedUser: (state, { payload }: PayloadAction<User | null>) => {
      state.selectedUser = payload;
      state.selectedUserId = payload != null ? payload.id : -1;
    },
    setSelectedProduct: (state, { payload }: PayloadAction<Product | null>) => (
      { ...state, selectedProduct: payload }
    ),
    setSelectedWorkingTime: (state, { payload }: PayloadAction<WorkingTime | null>) => (
      { ...state, selectedWorkingTime: payload }
    ),
    setUsers: (state, { payload }: PayloadAction<User[]>) => (
      { ...state, users: payload }
    ),
    setRoles: (state, { payload }: PayloadAction<Role[]>) => (
      { ...state, roles: payload }
    ),
  },
});

