import { createRoot } from 'react-dom/client';
import { FocusStyleManager, PortalProvider } from '@blueprintjs/core';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { store } from './stores';

FocusStyleManager.onlyShowFocusOnTabs();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const app = (
  <Provider store={store}>
    <PortalProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </PortalProvider>
  </Provider>
);

root.render(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
