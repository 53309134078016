import React, { useEffect } from 'react';
import { OverlayToaster } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Routes from './routes';
import { RootState } from './stores';
import { createRoot } from 'react-dom/client';

const AppToast = OverlayToaster.createAsync({ className: 'app-toast' }, {
  // Use createRoot() instead of ReactDOM.render(). This can be deleted after
  // a future Blueprint version uses createRoot() for Toasters by default.
  domRenderer: (toaster, containerElement) => createRoot(containerElement).render(toaster),
});

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useSelector((s: RootState) => s.ui);
  const user = useSelector((s: RootState) => s.user);

  if (location.pathname !== '/login' && (user == null || user.id == null || user.id <= 0)) {
    navigate('/login');
  }

  useEffect(() => {
    if (toast.message !== '') {
      (async () => (await AppToast).show({ timeout: 3000, ...toast }))();
    }
  }, [toast]);

  return <Routes />;
};

export default App;