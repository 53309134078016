import ProductsService from '../../services/products';
import { AppThunk } from '..';
import { setLoading, setMessage } from '../ui';
import { setCategories, setProducts } from '.';

export function getProducts(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { token } = getState().user;

    try {
      const { data } = await ProductsService.getAll(token);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
        return;
      }
      dispatch(setProducts(data.data));
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function getProductCategories(): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { token } = getState().user;

    try {
      const { data } = await ProductsService.getCategories(token);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
        return;
      }
      dispatch(setCategories(data.data));
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function insertProduct(product: Product, cb: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { token } = getState().user;

    try {
      const { data } = await ProductsService.insert(token, product);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
        return;
      }

      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function updateProduct(product: Product, cb: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));

    const { token } = getState().user;

    try {
      const { data } = await ProductsService.update(token, product);
      dispatch(setLoading(false));
      if (data.response_code !== 0) {
        dispatch(setMessage({ intent: 'danger', message: data.message }));
        return;
      }

      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}

export function deleteProduct(id: number, cb: () => void): AppThunk {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { token } = getState().user;

    try {
      const { data } = await ProductsService.remove(token, id);
      if (data.response_code !== 0) {
        dispatch(setMessage(data.message));
      }

      dispatch(setLoading(false));
      if (cb != null) {
        cb();
      }
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}
