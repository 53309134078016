import { settingsSlice } from './settings';

export const {
  setSelectedLoss,
  setSelectedMachine,
  setSelectedProduct,
  setSelectedUserId,
  setSelectedUser,
  setSelectedWorkingTime,
  setUsers,
  setRoles,
} = settingsSlice.actions;

export default settingsSlice.reducer;
