import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  categories: LossesCategory[],
  losses: Losses[],
}

const initialState: State = {
  categories: [],
  losses: [],
};

export const lossesSlice = createSlice({
  name: 'losses',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<LossesCategory[]>) => (
      { ...state, categories: payload }
    ),
    setLosses: (state, { payload }: PayloadAction<Losses[]>) => (
      { ...state, losses: payload }
    ),
  },
});
