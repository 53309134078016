import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface State {
  machines: Machine[],
  states: MachineState[],
}

const initialState: State = {
  machines: [],
  states: [],
}

export const machineSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    setMachines: (state, { payload }: PayloadAction<Machine[]>) => (
      { ...state, machines: payload }
    ),
    setStates: (state, { payload }: PayloadAction<MachineState[]>) => (
      { ...state, states: payload }
    )
  }
});
