import { logsSlice } from './logs';

export const {
  setReports,
  setTimesheets,
  setMtbf,
  setMtbfMachines,
  setMttr,
  setMttrMachines,
  setBatchCount,
  setNonBatchCount,
  setBreakdownByCategories,
} = logsSlice.actions;

export default logsSlice.reducer;
