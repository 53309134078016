import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface DashboardState {
  reports: Report[],
  timesheets: Timesheet[],
  mttr: number,
  mtbf: number,
  mttrMachines: MttrMachine[],
  mtbfMachines: MtbfMachine[],
  batchCount: number,
  nonBatchCount: number,
  breakdownByCategories: BreakdownByCategory[],
}

const initialState: DashboardState = {
  reports: [],
  timesheets: [],
  mttr: 0,
  mtbf: 0,
  mttrMachines: [],
  mtbfMachines: [],
  batchCount: 0,
  nonBatchCount: 0,
  breakdownByCategories: [],
};

export const logsSlice = createSlice({
  name: 'logs',
  initialState: initialState,
  reducers: {
    setMttr: (state, { payload }: PayloadAction<number>) => {
      state.mttr = payload;
    },
    setMtbf: (state, { payload }: PayloadAction<number>) => {
      state.mtbf = payload;
    },
    setBatchCount: (state, { payload }: PayloadAction<number>) => {
      state.batchCount = payload;
    },
    setBreakdownByCategories: (state, { payload }: PayloadAction<BreakdownByCategory[]>) => {
      state.breakdownByCategories = payload;
    },
    setNonBatchCount: (state, { payload }: PayloadAction<number>) => {
      state.nonBatchCount = payload;
    },
    setMttrMachines: (state, { payload }: PayloadAction<MttrMachine[]>) => {
      state.mttrMachines = payload;
    },
    setMtbfMachines: (state, { payload }: PayloadAction<MtbfMachine[]>) => {
      state.mtbfMachines = payload;
    },
    setReports: (state, { payload }: PayloadAction<Report[]>) => {
      state.reports = payload;
    },
    setTimesheets: (state, { payload }: PayloadAction<Timesheet[]>) => {
      state.timesheets = payload;
    },
  },
});
