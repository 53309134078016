import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DeviceState {
  machineStates: MachineState[],
  deviceCredential: DeviceCredential,
  mqttConnect: boolean,
}

const initialState: DeviceState = {
  machineStates: [],
  deviceCredential: {
    id: {
      id: '',
    },
    createdTime: Date.now(),
    credentialsId: '',
    credentialsType: '',
    credentialsValue: '',
    deviceId: {
      id: '',
      entityType: '',
    },
  },
  mqttConnect: false,
};

const getInitialState = (): DeviceState => {
  return initialState;
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState: getInitialState(),
  reducers: {
    setMachineState: (state, { payload }: PayloadAction<MachineState[]>) => (
      { ...state, machineStates: payload }
    ),
    setDevice: (state, { payload }: PayloadAction<DeviceState>) => (
      { ...state, deviceCredential: payload.deviceCredential, mqttConnect: payload.mqttConnect }
    ),
  },
});
