import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReportDetailState {
  report?: Report | null,
  timesheets: Timesheet[],
  telemetries: Telemetry[],
  durations: ReportDurations,
}

const initialState: ReportDetailState = {
  report: null,
  telemetries: [],
  timesheets: [],
  durations: {
    breakTime: 0,
    downTime: 0,
    plannedStopTime: 0,
    shiftTime: 0,
    idleTime: 0,
    runTime: 0,
  }
};

export const reportDetailSlice = createSlice({
  name:  'reportdetail',
  initialState,
  reducers: {
    setDurations: (state, { payload }: PayloadAction<ReportDurations>) => {
      state.durations = payload;
    },
    setReport: (state, { payload }: PayloadAction<Report | null | undefined>) => {
      state.report = payload;
    },
    setTelemetries: (state, { payload }: PayloadAction<Telemetry[]>) => {
      state.telemetries = payload;
    },
    setTimesheets: (state, { payload }: PayloadAction<Timesheet[]>) => {
      state.timesheets = payload;
    },
  }
});
