import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Routes, Route } from "react-router-dom";
import MainBase from '../components/layouts/MainBase';
import { RootState } from '../stores';

const load = (Component: any) => (props: any) => (
  <Suspense fallback={<><div>Please Wait..</div></>}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = load(lazy(() => import('../pages/Dashboard')));
const DataLog = load(lazy(() => import('../pages/DataLog')));
const DeviceInfoBoard = load(lazy(() => import('../pages/DeviceInfoBoard')));
const Insight = load(lazy(() => import('../pages/Insight')));
const Login = load(lazy(() => import('../pages/Login')));
const Maintenance = load(lazy(() => import('../pages/Maintenance')));
const Operation = load(lazy(() => import('../pages/Operation')));
const Settings = load(lazy(() => import('../pages/Settings')));
const Spareparts = load(lazy(() => import('../pages/Spareparts')));

const ProtectedRoutes: React.FC<{ user: User }> = ({ user }) => {  
  if (user == null || user.id == null || user.id <= 0) {
    return <Navigate replace to="/login" />;
  }

  return (
    <MainBase>
      <Routes>
        <Route path='/' element={user.machineId == null ? <Dashboard /> : <Operation />} />
        <Route path='/device/info/:id' element={<DeviceInfoBoard />} />
        <Route path='/insight/*' element={<Insight />} />
        <Route path='/log/*' element={<DataLog />} />
        <Route path='/maintenance/*' element={<Maintenance />} />
        {user.machineId != null && <Route path='/operation' element={<Operation />} />}
        <Route path='/settings/*' element={<Settings />} />
        <Route path='/sparepart/*' element={<Spareparts />} />
      </Routes>
    </MainBase>
  );
};

const AppRoutes: React.FC = () =>  {
  const user = useSelector((s: RootState) => s.user);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<ProtectedRoutes user={user} />} />
    </Routes>
)};

export default AppRoutes;
