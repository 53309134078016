import React from 'react';
import { Button, Classes, Menu, MenuItem, MenuDivider, Navbar, Popover, Spinner, SpinnerSize, H6 } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { RootState } from '../../../stores';
import { clearLocalStorage } from '../../../utils/storage';
import styles from './header.module.css';
import { format } from 'date-fns';

const PopupProfile: React.FC = () => {
  const navigate = useNavigate();
  const logout = () => {
    clearLocalStorage();
    navigate('/login');
  };

  return (
    <Menu>
      <MenuItem icon="user" onClick={() => navigate('/profile')} text="Profile" />
      <MenuDivider />
      <MenuItem icon="log-out" onClick={logout} text="Logout" />
    </Menu>
  );
};

const Navigation: React.FC = () => {
  const location = useLocation();
  const { menus } = useSelector((s: RootState) => s.ui);

  const buttonClass = [Classes.BUTTON, Classes.MINIMAL];

  return (
    <>
      <Navbar.Group>
        {menus.length > 0 && <Navbar.Divider />}
        {menus.map((v) => {
          const active = location.pathname === v.to;
          const className = [...buttonClass, active ? Classes.ACTIVE : ''].filter(Boolean).join(' ');
          return (<Link className={className} key={v.title} to={v.to}>{v.title}</Link>);
        })}
      </Navbar.Group>
    </>
  );
};

const Header: React.FC = () => {
  const { loading, title } = useSelector((s: RootState) => s.ui);

  return (
    <Navbar className={styles.root}>
      <Navbar.Group>
        <H6 className={styles.title}>{title}</H6>
      </Navbar.Group>
      <Navigation />
      <Navbar.Group align="right">
        {loading && <Spinner size={SpinnerSize.SMALL} style={{ marginRight: 10 }} />}
        <H6 className={styles.date}>{format(new Date(), 'EEE, d MMM yyyy')}</H6>
        <Navbar.Divider />
        <Popover
          usePortal
          content={<PopupProfile />}
          positioningStrategy="fixed"
          renderTarget={({ isOpen, ...targetProps }) => <Button { ...targetProps } icon="user" minimal />}
        />
      </Navbar.Group>
    </Navbar>
  );
};

export default Header;
