import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  mttr: MTTR[],
}

const initialState: State = {
  mttr: [],
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setMttr: (state, { payload }: PayloadAction<MTTR[]>) => {
      state.mttr = payload;
    },
  },
});
