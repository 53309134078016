import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { RootState } from '../../../stores';
import { setSidebar } from '../../../stores/ui';
import { saveSidebar } from '../../../utils/storage';
import styles from './mainbase.module.css';

interface Props {
  children?: React.ReactNode,
}

const MainBase: React.FC<Props> = ({ children }: Props) => {
  const dispatch = useDispatch();
  const { sidebar } = useSelector((s: RootState) => s.ui);

  const onCollapse = () => {
    const state = sidebar === 'collapse' ? 'expand' : 'collapse';
    saveSidebar(state);
    dispatch(setSidebar(state));
  };

  return (
    <div className={styles.root}>
      <div className={styles.sidebar}>
        <Sidebar collapse={sidebar === 'collapse'} onCollapse={onCollapse} />
      </div>
      <div className={styles.body}>
        <Header />
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainBase;
