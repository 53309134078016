import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  workingTimes: WorkingTime[],
}

const initialState: State = {
  workingTimes: [],
};

export const workingTimeSlice = createSlice({
  name: 'workingtime',
  initialState,
  reducers: {
    setWorkingTimes: (state, { payload }: PayloadAction<WorkingTime[]>) => (
      { ...state, workingTimes: payload }
    ),
  },
});
