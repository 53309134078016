import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  categories: ProductCategory[],
  products: Product[],
}

const initialState: State = {
  categories: [],
  products: [],
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCategories: (state, { payload }: PayloadAction<ProductCategory[]>) => (
      { ...state, categories: payload }
    ),
    setProducts: (state, { payload }: PayloadAction<Product[]>) => (
      { ...state, products: payload }
    ),
  },
});
