import { Intent } from '@blueprintjs/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getSidebar } from '../utils/storage';

export interface UIToast {
  intent: Intent,
  message: string,
}

export interface TitleMenu {
  title: string,
  menus: UIMenu[],
}

interface UIState {
  loading: boolean,
  numOfReq: number,
  menus: UIMenu[],
  toast: UIToast,
  sidebar: SidebarState,
  title: string,
}

const initialState: UIState = {
  loading: false,
  numOfReq: 0,
  menus: [],
  toast: { intent: 'none', message: '' },
  sidebar: getSidebar() || 'expand',
  title: '',
};

export const uiSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      let numOfReq = state.numOfReq;
      if (payload) {
        numOfReq += 1;
      } else {
        numOfReq -= 1;
      }

      if (numOfReq < 0) {
        numOfReq = 0;
      }

      const loading = numOfReq > 0;
      return { ...state, loading, numOfReq };
    },
    setMessage: (state, { payload }: PayloadAction<UIToast>) => (
      { ...state, toast: payload }
    ),
    setSidebar: (state, { payload }: PayloadAction<SidebarState>) => (
      { ...state, sidebar: payload }
    ),
    setTitle: (state, { payload }: PayloadAction<string>) => (
      { ...state, title: payload, menus: [] }
    ),
    setTitleMenus: (state, { payload }: PayloadAction<TitleMenu> ) => (
      { ...state, title: payload.title, menus: payload.menus }
    ),
  },
});

export const { setLoading, setMessage, setSidebar, setTitle, setTitleMenus } = uiSlice.actions;

export default uiSlice.reducer;
