import axios, { AxiosResponse } from 'axios';
import { getBearerHeader } from '../utils/services';
import { BASE_URL } from './constants';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getById(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses/${id}`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getCategories(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses/categories`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, losses: Losses): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses`;
  return axios.post(url, losses, { headers: getBearerHeader(token) });
}

function update(token: string, losses: Losses): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses`;
  return axios.put(url, losses, { headers: getBearerHeader(token) });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/losses/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

const LossesService = {
  getAll,
  getById,
  getCategories,
  insert,
  update,
  remove,
};

export default LossesService;
