import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  noSchedule: boolean,
  report?: Report | null,
  timesheets: Timesheet[],
}

const initialState: State = {
  noSchedule: false,
  report: null,
  timesheets: [],
};

export const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {
    setNoSchedule: (state, { payload }: PayloadAction<boolean>) => {
      state.noSchedule = payload;
    },
    setReport: (state, { payload }: PayloadAction<Report | null>) => {
      state.report = payload;
    },
    setTimesheets: (state, { payload }: PayloadAction<Timesheet[]>) => {
      state.timesheets = payload;
    },
  },
});
