import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

interface FilterState {
  selectDate: Date,
  machineID: number,
  filterLog: FilterLog,
}

const initialState: FilterState = {
  selectDate: new Date(),
  machineID: 0,
  filterLog: {
    from: format(new Date(), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
    shift: 0,
    machine: 0,
    types: '',
    product: 0,
    batch: '',
    zeroFilter: false, 
  }
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setMachineID: (state, { payload }: PayloadAction<number>) => {
      return { ...state, machineID: payload };
    },
    setSelectedDate: (state, { payload }: PayloadAction<Date>) => {
      return { ...state, selectDate: payload };
    },
    setFilterLog: (state, { payload }: PayloadAction<FilterLog>) => {
      return { ...state, filterLog: payload };
    },
  },
});

export const { setMachineID, setSelectedDate, setFilterLog } = filterSlice.actions;

export default filterSlice.reducer;
