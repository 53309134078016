import { configureStore, Action, ThunkAction, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import dashboard from './dashboard';
import devices from './devices';
import logs from './logs';
import losses from './losses';
import machines from './machines';
import maintenance from './maintenance';
import operation from './operation';
import products from './products';
import reportDetail from './reportdetail';
import settings from './settings';
import ui from './ui';
import user from './user';
import filter from './filter';
import workingtimes from './workingtimes';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    dashboard,
    devices,
    logs,
    losses,
    machines,
    maintenance,
    operation,
    products,
    reportDetail,
    settings,
    ui,
    user,
    filter,
    workingtimes,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppThunk<ReturnType = void> =
  ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
