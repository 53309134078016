import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUser } from '../../utils/storage';

const initialState: User = {
  id: -1,
  username: '',
  fullname: '',
  token: '',
  roleId: -1,
};

const getInitialState = (): User => {
  const user = getUser();
  return user == null ? initialState : user;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    setUser: (state, { payload }: PayloadAction<User>) => ({ ...state, ...payload }),
  },
});
