import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Classes, Icon } from '@blueprintjs/core';
import { Link, useLocation } from 'react-router-dom';
import { setTitleMenus } from '../../../stores/ui';
import logo from '../../../assets/diatera_white.png';
import logoOnly from '../../../assets/diatera_logo_only.png';
import { ContextValues, MenuProps, SidebarProps } from './interfaces';
import styles from './sidebar.module.css';
import { RootState } from '../../../stores';

const Context = createContext<ContextValues>({});

const Menu: React.FC<MenuProps> = (props: MenuProps) => {
  const { children, icon, to } = props;
  const { collapse } = useContext(Context);
  const location = useLocation();

  const active = `/${location.pathname.split('/')[1]}` === to;
  const className = [
    Classes.BUTTON,
    Classes.FILL,
    Classes.MINIMAL,
    active ? Classes.ACTIVE : '',
  ].filter(Boolean).join(' ');

  return (
    <Link className={className} to={to} style={{ borderRadius:0 }}>
      <div className={styles.btnContainer}>
        <Icon icon={icon} color='#fff' />
        {collapse ? '' : children}
      </div>
    </Link>
  );
};

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  const dispatch = useDispatch();
  const { machineId, roleId } = useSelector((s: RootState) => s.user);

  useEffect(() => {
    dispatch(setTitleMenus({ title: '', menus: [] }));
  }, [dispatch]);

  const { collapse, onCollapse } = props;

  return (
    <Context.Provider value={{ collapse }}>
      <div className={[Classes.DARK, styles.root, collapse ? styles.collapse : ''].filter(Boolean).join(' ')}>
        <img alt="Diatera Technology" src={collapse ? logoOnly : logo} />

        <div className={styles.menu}>
          {machineId == null && <Menu icon="dashboard" to="/">Dashboard</Menu>}
          {machineId != null && <Menu icon="cube" to="/operation">Operation</Menu>}
          <Menu icon="list-detail-view" to="/log">Data Log</Menu>
          <Menu icon="trending-up" to="/insight">Insight</Menu>
          {roleId <= 3 && (
            <>
              <Menu icon="diagnosis" to="/maintenance">Maintenance</Menu>
              {/* <Menu icon="build" to="/sparepart">Sparepart</Menu> */}
              <Menu icon="cog" to="/settings/users">Settings</Menu>
            </>
          )}
        </div>

        <Button
          minimal
          className={styles.button}
          onClick={onCollapse}
        >
          <div className={styles.btnContainer}>
            <Icon
              color="#fff"
              icon={collapse ? 'double-chevron-right' : 'double-chevron-left'}
            />
            {collapse ? '' : 'Collapse'}
          </div>
        </Button>
      </div>
    </Context.Provider>
  );
};

export default Sidebar;
