export const BASE_URL_MQTT = 'http://localhost:8089';

// export const BASE_UR = 'http://192.168.0.183:8086';
// export const BASE_URL = 'http://localhost:8000';
export const BASE_URL = 'https://oee-api.diatera.com';
// export const BASE_URL = 'http://localhost:8000';
// export const BASE_URL = 'http://103.175.218.246:9006';


//deploy
// export const BASE_URL = 'http://10.167.167.122:9000';
