import axios, { AxiosResponse } from 'axios';
import { getBearerHeader } from '../utils/services';
import { BASE_URL } from './constants';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getById(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products/${id}`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getCategories(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products/categories`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, product: Product): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products`;
  return axios.post(url, product, { headers: getBearerHeader(token) });
}

function update(token: string, product: Product): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products`;
  return axios.put(url, product, { headers: getBearerHeader(token) });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/products/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

const ProductsService = {
  getAll,
  getById,
  getCategories,
  insert,
  update,
  remove,
};

export default ProductsService;
